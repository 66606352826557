import { useTranslation } from 'next-i18next'
import React, { useCallback, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { IGameCategory, NSClass, NSProduct } from 'gga-types'
import { useCourseCenter } from '@/pages/course-center/container/CourseCenterContext'
import ProductCategoryHeader from '@/pages/course-center/components/ProductCategoryHeader'
import useModal from '@/components/modal/useModal'
import { useAuth } from '@/containers/auth'
import { CashierModal } from '@/components/Modals/CashierModal'
import ProductCards from '@/pages/course-center/components/ProductCards'
import FilterSelector from '@/pages/my-class/components/views/LibraryView/FilterSelector'
import { APP_LOCALE } from '@/constants/define'

interface CourseCenterRootProps {}

export default function CourseCenterRoot(props: CourseCenterRootProps) {
  const { t, i18n } = useTranslation('common')

  const {
    groupingProducts,
    selectedGame,
    existGames,
    existPrograms,
    selectedProgram,
    existedTimeSpan,
    selectedTimeSpan,
  } = useCourseCenter()

  const [product, setProduct] = useState<NSProduct.Product>()
  const { isOpen: showCashier, toggle: toggleCashier } = useModal()
  const router = useRouter()
  const { isAuthenticated, setOpenAuthModal } = useAuth()

  const clickProductHandler = useCallback(
    product => {
      if (APP_LOCALE === 'zh_CN') {
        toggleCashier(true)
        setProduct(product)
      } else {
        if (product.type === 'Normal') {
          router.push({
            pathname: '/cashier',
            query: {
              productId: product.id,
            },
          })
        } else {
          router.push(
            {
              pathname: '/subscription',
              query: {
                productId: product.id,
              },
            },
            undefined,
            {
              shallow: true,
            }
          )
        }
      }
    },
    [router, toggleCashier]
  )

  useEffect(() => {
    if (APP_LOCALE === 'zh_CN') {
      if (showCashier && !isAuthenticated) {
        setOpenAuthModal(true)
        toggleCashier(false)
      } else if (product !== undefined && isAuthenticated) {
        toggleCashier(true)
      }
    }
  }, [isAuthenticated, product, setOpenAuthModal, showCashier, toggleCashier])

  return (
    <div>
      <div className="container max-w-7xl mx-auto pt-8 px-4">
        <section className={'mb-4 space-y-2'}>
          <FilterSelector<string | null, NSClass.ClassProgramType | null>
            size={'small'}
            options={[
              {
                label: t('all'),
                value: null,
                origin: null,
              },
              ...(existPrograms || []).map(gc => ({
                label: gc.name === 'Basic' ? 'Play' : gc.name || t(gc.name),
                value: gc.name,
                origin: gc,
              })),
            ]}
            category={t('program')}
            onChange={opt => {
              if (opt) {
                router.push(
                  {
                    pathname: router.pathname,
                    query: {
                      ...router.query,
                      program: opt,
                    },
                  },
                  undefined,
                  {
                    shallow: true,
                  }
                )
              } else {
                const newQuery = { ...router.query }
                delete newQuery['program']
                router.push(
                  {
                    pathname: router.pathname,
                    query: {
                      ...newQuery,
                    },
                  },
                  undefined,
                  {
                    shallow: true,
                  }
                )
              }
            }}
            active={o => {
              if (o.origin === null) {
                return o.origin === selectedProgram
              }
              return Boolean(o.origin && o.origin.id === selectedProgram)
            }}
          />

          <FilterSelector<string | null, IGameCategory | null>
            size={'small'}
            options={[
              {
                label: t('all'),
                value: null,
                origin: null,
              },
              ...(existGames || [])
                .filter(g => !g.hidden)
                .map(gc => ({
                  label: t(gc.display_code as string) || gc.name,
                  value: gc.code,
                  origin: gc,
                })),
            ]}
            category={t('game')}
            onChange={opt => {
              if (opt) {
                router.push(
                  {
                    pathname: router.pathname,
                    query: {
                      ...router.query,
                      game: opt,
                    },
                  },
                  undefined,
                  {
                    shallow: true,
                  }
                )
              } else {
                const newQuery = { ...router.query }
                delete newQuery['game']
                router.push(
                  {
                    pathname: router.pathname,
                    query: newQuery,
                  },
                  undefined,
                  {
                    shallow: true,
                  }
                )
              }
            }}
            active={o => {
              if (o.origin === null) {
                return o.origin === selectedGame
              }
              return Boolean(o.origin && o.origin.id === selectedGame)
            }}
          />

          <FilterSelector<string | null, any | null>
            size={'small'}
            options={[
              {
                label: t('all'),
                value: null,
                origin: null,
              },
              ...(existedTimeSpan || []).map(o => {
                return {
                  label: o.label,
                  value: o.value,
                  origin: o,
                }
              }),
            ]}
            category={t('time_span')}
            onChange={opt => {
              if (opt) {
                router.push(
                  {
                    pathname: router.pathname,
                    query: {
                      ...router.query,
                      timeSpan: opt,
                    },
                  },
                  undefined,
                  {
                    shallow: true,
                  }
                )
              } else {
                const newQuery = { ...router.query }
                delete newQuery['timeSpan']
                router.push(
                  {
                    pathname: router.pathname,
                    query: newQuery,
                  },
                  undefined,
                  {
                    shallow: true,
                  }
                )
              }
            }}
            active={o => {
              return Boolean(o.value === selectedTimeSpan)
            }}
          />
        </section>
        {Object.keys(groupingProducts).map(groupTitle => {
          const products = groupingProducts[groupTitle]
          return (
            <div key={groupTitle}>
              <ProductCategoryHeader
                title={<div className={'text-gold text-lg'}>{groupTitle}</div>}
              />
              <ProductCards
                onSelect={clickProductHandler}
                products={products}
              />
            </div>
          )
        })}
      </div>

      {product && (
        <CashierModal
          product={product}
          isOpen={showCashier}
          onRequestClose={() => {
            setProduct(undefined)
            toggleCashier()
          }}
        />
      )}
    </div>
  )
}
